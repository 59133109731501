import axios from 'axios';

export default class ProductTypeService {

	async getProductBrands() {
		return await axios.get('https://advantex.uz/product/selectProductBrand').then(res => res.data);
	}

	deleteProductBrand(deletingBrand) {
		var data = JSON.stringify({
			"id": deletingBrand.id
		});

		var config = {
			method: 'post',
			url: 'https://advantex.uz/product/deleteProductBrand',
			headers: {
				'Content-Type': 'application/json',
			},
			data: data
		};

		return axios(config)
	}

	insertNewProductBrand(newBrand) {

		var data = JSON.stringify({
			"array": {
				"name": newBrand.name,
			}
		});

		var config = {
			method: 'post',
			url: 'https://advantex.uz/product/insertProductBrand',
			headers: {
				'Content-Type': 'application/json',
			},
			data: data
		};

		return axios(config)
	}

	updateProductBrand(updatingBrand) {
		var data = JSON.stringify({
			"array": {
				"name": updatingBrand.name,
			},
			"id": updatingBrand.id
		});

		var config = {
			method: 'post',
			url: 'https://advantex.uz/product/updateProductBrand',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return axios(config)
	}

	async getProductTypes() {
		return await axios.get('https://advantex.uz/product/getProductType').then(res => res.data);
	}

	getProductTypesWithOrdersSmall() {
		return axios.get('assets/layout/data/products-orders-small.json').then(res => res.data.data);
	}

	insertNewProductType(newProdType) {

		var data = JSON.stringify({
			"array": {
				"orig_name": newProdType.orig_name,
				"name_ru": newProdType.name_ru,
				"name_uz": newProdType.name_uz,
			}
		});

		var config = {
			method: 'post',
			url: 'https://advantex.uz/product/insert_product_type',
			headers: {
				'Content-Type': 'application/json',
			},
			data: data
		};

		return axios(config)
		// .then(function (response) {
		//   console.log(JSON.stringify(response.data));
		// })
		// .catch(function (error) {
		//   console.log(error);
		// });

	}

	updateProductType(updatingProdType) {
		var data = JSON.stringify({
			"array": {
				"orig_name": updatingProdType.orig_name,
				"name_ru": updatingProdType.name_ru,
				"name_uz": updatingProdType.name_uz,
			},
			"id": updatingProdType.id
		});

		var config = {
			method: 'post',
			url: 'https://advantex.uz/product/update_product_type',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return axios(config)
		// .then(function (response) {
		//   console.log(JSON.stringify(response.data));
		// })
		// .catch(function (error) {
		//   console.log(error);
		// });
	}

	deleteProductType(deletingProdType) {
		var data = JSON.stringify({
			"id": deletingProdType.id
		});

		var config = {
			method: 'delete',
			url: 'https://advantex.uz/product/deleteProductType',
			headers: {
				'Content-Type': 'application/json',
			},
			data: data
		};

		return axios(config)
		// .then(function (response) {
		//   console.log(response.data);
		//   return response.data
		// })
		// .catch(function (error) {
		//   console.log(error);
		//   return error
		// });  
	}


}